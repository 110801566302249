import React from "react";
import { Routes, Route, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
// Screens
import Landing from "./screens/Landing.jsx";
import Setup from "./screens/Setup.jsx";
import Dashboard_ from "./screens/Dashboard.jsx";
import SignIn_ from "./screens/SignIn.jsx";
import SignUp_ from "./screens/SignUp.jsx";
import Calculator from "./screens/Calculator.jsx";
import RedirectToHome from './screens/RedirectToHome';
import PrivacyPolicy from "./components/Sections/PrivacyPolicy.jsx";

export default function App() {
  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap" rel="stylesheet" />
      </Helmet>
      <Routes>
        <Route path="/" element={<Landing />} /> 
        <Route path="/setup" element={<Setup_Wrapper />} /> {/* Rota padrão para Setup */}
        <Route path="/:company/setup" element={<Setup_Wrapper />} /> {/* Rota white label para Setup */}
        <Route path="/signup" element={<SignUp_ />} />
        <Route path="/signin" element={<SignIn_ />} /> {/* Rota padrão de SignIn */}
        <Route path="/:company/signin" element={<SignIn_ />} /> {/* Rota white label para SignIn */}
        <Route path="/dashboard" element={<Dashboard_ />} /> {/* Rota padrão de Dashboard */}
        <Route path="/:company/dashboard" element={<Dashboard_ />} /> {/* Rota white label para Dashboard */}
        <Route path="/calculator" element={<Calculator />} />
        <Route path="/teste-gratis-com-sua-marca" element={<RedirectToHome />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
      </Routes>
    </>
  );
}

// // Wrapper para Dashboard com roteamento dinâmico
// function Dashboard_Wrapper() {
//   const { company } = useParams(); // Captura o parâmetro da empresa da URL
//   return <Dashboard_ company={company} />; // Passa o parâmetro para o componente Dashboard
// }


// Wrapper para Setup com roteamento dinâmico
function Setup_Wrapper() {
  const { company } = useParams();
  return <Setup company={company} />;
}
