import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import CampaignIcon from '@mui/icons-material/Campaign';
import AssistantIcon from '@mui/icons-material/Assistant';
import Typography from '@mui/material/Typography';

// const dashboardMarcaColor = '#e2aa13';
// const dashboardCampanhaColor = '#6c9434';

const getColorStyles = (color) => ({
  '& .MuiListItemIcon-root': {
    color: color,
  },
  borderLeft: `4px solid ${color}`,
});

export const mainListItems = ({
  companyConfig,
  onUpdateFilterConcorrente1, 
  onUpdateFilterConcorrente2, 
  onUpdateFilterConcorrente3, 
  onUpdateOnFilterMarca, 
  onUpdateOnFilterSegmento,
  onUpdateOnFilterPublicacao,
  disabledItems = {},
  selectedItemId,
  setSelectedItemId
}) => (
  <React.Fragment>
    <ListItemButton
      id="dashboard-menu-item"  // Set the CSS selector
      disabled={disabledItems.marca} 
      onClick={() => {
        if (!disabledItems.marca) {
          onUpdateOnFilterMarca();
          setSelectedItemId('marca');
        }
      }}
      selected={selectedItemId === 'marca'}
      sx={{
        opacity: disabledItems.marca ? 0.5 : 1,
        ...getColorStyles(companyConfig?.primaryColor),
        '&.Mui-selected': {
          backgroundColor: 'rgba(0, 0, 0, 0.08)',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
          },
        },
      }}
    >
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText
        primary="Minha Marca"
        secondary="Entenda o momento da sua marca e os porquês"
        primaryTypographyProps={{ variant: 'subtitle1' }}
        secondaryTypographyProps={{ variant: 'body2', sx: { whiteSpace: 'pre-line', fontSize: '0.8rem' } }}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'center',
          '& > *:not(:first-child):not(:last-child)': {
            marginTop: '4px',
          },
        }}
      />
    </ListItemButton>
  </React.Fragment>
);

export const secondaryListItems = ({
  companyConfig,
  onUpdateOnFilterCampanha, 
  onUpdateOnFilterCompara, 
  onUpdateOnFilterSwot, 
  onUpdateOnFilterAcao,
  disabledItems = {},
  selectedItemId,
  setSelectedItemId,
  toggleActionAssistant
}) => (
  <React.Fragment>
    <ListItemButton
      id="swot-menu-item"  // Set the CSS selector
      disabled={disabledItems.swot} 
      onClick={() => {
        if (!disabledItems.swot) {
          onUpdateOnFilterSwot();
          setSelectedItemId('swot');
        }
      }}
      selected={selectedItemId === 'swot'}
      sx={{
        opacity: disabledItems.swot ? 0.5 : 1,
        ...getColorStyles(companyConfig?.primaryColor),
        '&.Mui-selected': {
          backgroundColor: 'rgba(0, 0, 0, 0.08)',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
          },
        },
      }}
    >
      <ListItemIcon>
        <TroubleshootIcon />
      </ListItemIcon>
      <ListItemText
        primary="SWOT Avançada"
        secondary="Uma foto de como sua marca está sendo percebida, gerada pelo Ian"
        primaryTypographyProps={{ variant: 'subtitle1' }}
        secondaryTypographyProps={{ variant: 'body2', sx: { whiteSpace: 'pre-line', fontSize: '0.8rem' } }}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'center',
          '& > *:not(:first-child):not(:last-child)': {
            marginTop: '4px',
          },
        }}
      />
    </ListItemButton>
    <ListItemButton
      id="compara-menu-item"  // Set the CSS selector
      disabled={disabledItems.compara} 
      onClick={() => {
        if (!disabledItems.compara) {
          onUpdateOnFilterCompara();
          setSelectedItemId('compara');
        }
      }}
      selected={selectedItemId === 'compara'}
      sx={{
        opacity: disabledItems.compara ? 0.5 : 1,
        ...getColorStyles(companyConfig?.primaryColor),
        '&.Mui-selected': {
          backgroundColor: 'rgba(0, 0, 0, 0.08)',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
          },
        },
      }}
    >
      <ListItemIcon>
        <CompareArrowsIcon />
      </ListItemIcon>
      <ListItemText
        primary="ACPS® Compara"
        secondary="Compare seus objetivos de negócio com seu posicionamento digital"
        primaryTypographyProps={{ variant: 'subtitle1' }}
        secondaryTypographyProps={{ variant: 'body2', sx: { whiteSpace: 'pre-line', fontSize: '0.8rem' } }}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'center',
          '& > *:not(:first-child):not(:last-child)': {
            marginTop: '4px',
          },
        }}
      />
    </ListItemButton>
    <ListItemButton
      id="acao-menu-item"  // Set the CSS selector
      disabled={disabledItems.acao} 
      onClick={() => {
        if (!disabledItems.acao) {
          // onUpdateOnFilterAcao();
          setSelectedItemId('acao');
          toggleActionAssistant();
        }
      }}
      selected={selectedItemId === 'acao'}
      sx={{
        opacity: disabledItems.acao ? 0.5 : 1,
        ...getColorStyles(companyConfig?.primaryColor),
        '&.Mui-selected': {
          backgroundColor: 'rgba(0, 0, 0, 0.08)',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
          },
        },
      }}
    >
      <ListItemIcon>
        <AssistantIcon />
      </ListItemIcon>
      <ListItemText
        primary="Assistente de ação"
        secondary={
          <React.Fragment>
            Converse com o Ian para explorar cenários, analisar, entender e guiar você nos seus objetivos
            <Typography
              component="span"
              variant="caption"
              sx={{
                display: 'block',
                backgroundColor: '#ffeb3b',
                color: '#000',
                borderRadius: '4px',
                padding: '2px 4px',
                marginTop: '4px',
              }}
            >
              Novidade
            </Typography>
          </React.Fragment>
        }
        primaryTypographyProps={{ variant: 'subtitle1' }}
        secondaryTypographyProps={{ variant: 'body2', sx: { whiteSpace: 'pre-line', fontSize: '0.8rem' } }}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'center',
          '& > *:not(:first-child):not(:last-child)': {
            marginTop: '4px',
          },
        }}
      />
    </ListItemButton>
    <ListItemButton
      id="campanha-menu-item"  // Set the CSS selector
      disabled={disabledItems.campanha} 
      onClick={() => {
        if (!disabledItems.campanha) {
          onUpdateOnFilterCampanha();
          setSelectedItemId('campanha');
        }
      }}
      selected={selectedItemId === 'campanha'}
      sx={{
        opacity: disabledItems.campanha ? 0.5 : 1,
        ...getColorStyles(companyConfig?.secondaryColor),
        '&.Mui-selected': {
          backgroundColor: 'rgba(0, 0, 0, 0.08)',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
          },
        },
      }}
    >
      <ListItemIcon>
        <CampaignIcon />
      </ListItemIcon>
      <ListItemText
        primary="Campanha"
        secondary="Meça o resultado de suas campanhas de marketing de forma eficiente"
        primaryTypographyProps={{ variant: 'subtitle1' }}
        secondaryTypographyProps={{ variant: 'body2', sx: { whiteSpace: 'pre-line', fontSize: '0.8rem' } }}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'center',
          '& > *:not(:first-child):not(:last-child)': {
            marginTop: '4px',
          },
        }}
      />
    </ListItemButton>
  </React.Fragment>
);
