import React from 'react';
import { Button, Menu, MenuItem, Avatar, ListItemIcon, ListItemText } from '@mui/material';
import { makeStyles, styled } from '@mui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';

const API_URI_CNPJS = `https://api-vinbolai-dev.azurewebsites.net/cnpjs/?cnpj=`;

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: '#9c27b0', // Ensures light mode style
    color: theme.palette.getContrastText(theme.palette.secondary.main),
  },
}));

const SquareSwitch = styled('div')(({ theme, checked }) => ({
  width: 34,
  height: 20,
  display: 'flex',
  alignItems: 'center',
  borderRadius: 2,
  padding: 2,
  cursor: 'pointer',
  backgroundColor: checked ? theme.palette.success.main : theme.palette.grey[400],
  '&:before': {
    content: '""',
    width: 16,
    height: 16,
    backgroundColor: theme.palette.common.white,
    transition: 'transform 0.2s',
    transform: checked ? 'translateX(14px)' : 'translateX(0)',
  },
}));

const ProfileMenu = ({ 
  clientName, 
  setNomeEmpresa, 
  groupNamesList, 
  isGroup, 
  onLogout, 
  currCnpj, 
  setCurrCnpj,
  setDisplayNomeEmpresa,
  setIsGroup,
  cnpj,
  activeTutorial, 
  setActiveTutorial, 
  updateOnCalculator
}) => {
  const classes = useStyles(); // Use styles here for Avatar
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [subMenuAnchorEl, setSubMenuAnchorEl] = React.useState(null);
  const [subMenuAnchorElCompany, setSubMenuAnchorElCompany] = React.useState(null);
  const [managedCompanies, setManagedCompanies] = React.useState({});
  
  // Initialize the default state for currCnpj and displayNomeEmpresa to Matriz
  React.useEffect(() => {
    if (groupNamesList.length > 0) {
      setCurrCnpj(groupNamesList[0].cnpj);
      setDisplayNomeEmpresa("Matriz");
    }
  }, [groupNamesList]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSubMenuClick = (event) => {
    setSubMenuAnchorEl(event.currentTarget);
  };

  const handleSubMenuCompanyClick = (event) => {
    setSubMenuAnchorElCompany(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSubMenuAnchorEl(null); // Close the submenu as well
    setSubMenuAnchorElCompany(null); // Close the company submenu
  };

  const handleTutorialToggle = () => {
    setActiveTutorial(!activeTutorial);
  };

  const formatBranch = (branchName) => {
    const match = branchName.match(/\((.*?)\)/);
    return match ? `Unidade ${match[1]}` : 'Grupo';
  };

  React.useEffect(() => {
    // Ensure that there are CNPJs to process
    if (cnpj.length > 0) {
      // Create a promise for each CNPJ to fetch its data
      const fetchPromises = cnpj.map((company) =>
        axios.get(`${API_URI_CNPJS}${company}`).then((res) => ({
          nome: res.data[0]?.nome_pesquisa, // Assuming res.data[0].nome_pesquisa contains the company name
          cnpj: company,
          isGroup: res.data[0]?.grupo
        }))
      );
  
      // Use Promise.all to wait for all fetch operations to complete
      Promise.all(fetchPromises)
        .then((companies) => {
          // Once all promises resolve, update your state with the fetched companies
          setManagedCompanies(companies);
        })
        .catch((error) => {
          console.error("Failed to fetch companies", error);
        })
        .finally(() => {
          handleClose(); // Close the modal or loading state once everything is done
        });
    }
  }, [cnpj]);

  // Lida com as empresas gerenciadas que são grupo - monta a string de cnpjs do grupo para fazer o fetch do ACPS corretamente.
  React.useEffect(() => {
    if (Object.keys(managedCompanies).length > 0) {
      managedCompanies?.map((item, index) => {
        if (item.isGroup) {
          const TOKEN = "VOkVTET8-2Qvrvl8qIHFRhUFDujQ1luE6yUZBmpuP1KtAzFu-8KV-A==";
          axios
          .get(`https://query-group-companies.azurewebsites.net/api/groupCnpjs?code=${TOKEN}&cnpj=${item.cnpj}`)
          .then(res => {
            item.cnpj = res.data.map(company => company.cnpj).join(',');
          });
        }
      });
    }
  }, [managedCompanies]);

  return (
    <>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        <Avatar className={classes.avatar}>{clientName.charAt(0)}</Avatar>
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {/* Conditional rendering */}
        {isGroup && (
          <MenuItem key="toggle-units" onClick={handleSubMenuClick}>
            <ListItemText>Alternar entre Unidades</ListItemText>
            <ListItemIcon>
              <ExpandMoreIcon />
            </ListItemIcon>
          </MenuItem>
        )}

        {/* Conditional rendering for "Minhas Marcas Gerenciadas" */}
        {cnpj.length > 1 && (
          <MenuItem key="my-managed-companies" onClick={handleSubMenuCompanyClick}>
            <ListItemText>Minhas Marcas Gerenciadas</ListItemText>
            <ListItemIcon>
              <ExpandMoreIcon />
            </ListItemIcon>
          </MenuItem>
        )}

        {/* Submenu for managing companies */}
        {cnpj.length > 1 && (
          <Menu
            key="sub-menu-company"
            id="sub-menu-company"
            anchorEl={subMenuAnchorElCompany}
            keepMounted
            open={Boolean(subMenuAnchorElCompany)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            {Object.keys(managedCompanies).length > 0 && managedCompanies?.map((item, index) => (
              <MenuItem
                key={index}
                onClick={() => {
                  setCurrCnpj(item.cnpj);
                  setDisplayNomeEmpresa(item.nome);
                  setIsGroup(item.isGroup);
                  handleClose();
                }}
              >
                {item.nome}
              </MenuItem>
            ))}
          </Menu>
        )}

        {/* Submenu for "Alternar entre Unidades" */}
        {isGroup && (
          <Menu
            key="sub-menu"
            id="sub-menu"
            anchorEl={subMenuAnchorEl}
            keepMounted
            open={Boolean(subMenuAnchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <MenuItem
              key="matriz"
              onClick={() => {
                setCurrCnpj(groupNamesList[0].cnpj);
                setDisplayNomeEmpresa("Matriz");
                handleClose();
              }}
            >
              Matriz
            </MenuItem>
            {Object.keys(groupNamesList).length > 0 && groupNamesList?.map((item, index) => (
              <MenuItem
                key={index}
                onClick={() => {
                  const cnpjValue = index === 0 ? groupNamesList?.map(item => item.cnpj).join(',') : item.cnpj;
                  setCurrCnpj(cnpjValue);
                  setDisplayNomeEmpresa(formatBranch(item.nome));
                  handleClose();
                }}
              >
                {formatBranch(item.nome)}
              </MenuItem>
            ))}
          </Menu>
        )}

        {/* New menu item for toggling tutorial */}
        <MenuItem onClick={handleTutorialToggle}>
          <ListItemText>Habilitar tutorial</ListItemText>
          <ListItemIcon>
            <SquareSwitch checked={activeTutorial} onClick={handleTutorialToggle} />
          </ListItemIcon>
        </MenuItem>

        <MenuItem onClick={onLogout}>Sair</MenuItem>
      </Menu>
    </>
  );
};

export default ProfileMenu;
