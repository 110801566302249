import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom'; // Adicionado useParams para capturar o parâmetro da empresa
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import { getAuth, GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup, sendPasswordResetEmail } from 'firebase/auth';
import styled from 'styled-components';
import GoogleIcon from '@mui/icons-material/Google';
import { ref, get, child } from 'firebase/database';
import { getStorage, ref as storageRef, getDownloadURL } from 'firebase/storage';
import { getDatabase } from 'firebase/database'; // Importar para obter o banco de dados Firebase

const defaultTheme = createTheme();

const GoogleButton = styled(Button)({
  backgroundColor: 'white',
  color: 'black',
  textTransform: 'none',
  border: '1px solid #ccc',
  padding: '10px 20px',
  borderRadius: '4px',
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: '#f5f5f5',
    boxShadow: 'none',
  },
});

// Definir o componente Copyright
function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Todos os direitos reservados ® '}
      <Link color="inherit" href="/">
        Vinbol
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function SignIn() {
  const [showDialog, setShowDialog] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [resetEmail, setResetEmail] = React.useState(false);
  const [emailError, setEmailError] = React.useState(false);
  const [passError, setPassError] = React.useState(false);
  const [showEmailForm, setShowEmailForm] = React.useState(false);
  const [logoUrl, setLogoUrl] = React.useState(null); // Novo estado para o logo
  const navigate = useNavigate();
  const auth = getAuth();
  const provider = new GoogleAuthProvider();

  const { company } = useParams(); // Obter o nome da empresa da URL

  // Busca o logo do Firebase quando é white-label
  React.useEffect(() => {
    if (company) {
      const dbRef = ref(getDatabase());
      const companyPath = company || 'default';

      // Buscar a configuração da empresa no Realtime Database
      get(child(dbRef, `white-label/companyConfigs/${companyPath}`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            const config = snapshot.val();

            // Carregar o logo correto
            const logoStorageRef = storageRef(
              getStorage(),
              config.logo.onLightTheme // Usando o logo claro como padrão para o SignIn
            );

            getDownloadURL(logoStorageRef)
              .then((url) => {
                setLogoUrl(url); // Define a URL do logo
              })
              .catch((error) => {
                console.error('Error fetching logo URL:', error);
              });
          } else {
            console.log("No data available for the company");
          }
        })
        .catch((error) => {
          console.error("Error fetching company config:", error);
        });
    }
  }, [company]); // Dependência do parâmetro da empresa

  const handleGoogleSignup = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      // Redirecionar para o dashboard correto com base na empresa
      if (company) {
        navigate(`/${company}/dashboard`);
      } else {
        navigate("/dashboard");
      }
    } catch (error) {
      console.error('Error during Google Sign-In', error);
    }
  };

  const onLogin = (e) => {
    e.preventDefault();
    if (email.trim() === '') {
      setEmailError(true);
      return;
    } else if (password.trim() === '') {
      setPassError(true);
      return;
    } else if (resetEmail) {
      triggerResetEmail();
      return;
    } else {
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Redirecionar para o dashboard correto com base na empresa
          if (company) {
            navigate(`/${company}/dashboard`);
          } else {
            navigate("/dashboard");
          }
        })
        .catch((error) => {
          const errorCode = error.code;
          if (errorCode === 'auth/invalid-login-credentials') {
            alert("Credenciais inválidas, por favor, revise o endereço de e-mail e senha digitados.");
          }
        });
    }
  };

  const triggerResetEmail = async () => {
    await sendPasswordResetEmail(auth, email);
    alert("E-mail de redefinição de senha enviado");
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <AppBar
        position="absolute"
        color="default"
        elevation={0}
        sx={{
          position: 'relative',
          borderBottom: (t) => `1px solid ${t.palette.divider}`,
        }}
      >
        <Toolbar>
          {/* Exibir logo da empresa se estiver disponível, caso contrário, exibir logo padrão */}
          {logoUrl ? (
            <img src={logoUrl} alt="Company Logo" width={100} />
          ) : (
            <LockOutlinedIcon />
          )}
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Entrar na {company ? company : 'Vinbol'}
          </Typography>
          {!showEmailForm ? (
            <React.Fragment>
              <GoogleButton
                variant="contained"
                startIcon={<GoogleIcon />}
                fullWidth
                onClick={handleGoogleSignup}
                sx={{ mt: 3, mb: 2 }}
              >
                Continuar com Google
              </GoogleButton>
              <Divider sx={{ width: '100%', mb: 2 }}>OU</Divider>
              <Button
                variant="contained"
                fullWidth
                onClick={() => setShowEmailForm(true)}
                sx={{ mb: 2 }}
              >
                Continuar com Email
              </Button>
            </React.Fragment>
          ) : (
            <Box component="form" onSubmit={onLogin} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Endereço de email"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={(e) => {
                  setEmail(e.target.value);
                  setEmailError(e.target.value.trim() === ''); // Checa se não está vazio
                }}
                error={emailError}
                helperText={emailError ? 'Campo obrigatório' : ''}
              />
              {!resetEmail ? (
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Senha"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setPassError(e.target.value.trim() === ''); // Checa se não está vazio
                  }}
                  error={passError}
                  helperText={passError ? 'Campo obrigatório' : ''}
                />
              ) : null}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                {!resetEmail ? "Entrar" : "Enviar instruções para o e-mail"}
              </Button>
              <Grid container>
                <Grid item xs>
                  {!resetEmail ? (
                    <Link href="#" onClick={() => setResetEmail(true)} variant="body2">
                      Esqueceu a senha?
                    </Link>
                  ) : (
                    <Link href="#" onClick={() => setResetEmail(false)} variant="body2" component="button">
                      Voltar para o login
                    </Link>
                  )}
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
