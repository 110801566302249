import * as React from 'react';
import { useParams } from "react-router-dom";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import LogoCircle from "../../../assets/svg/LogoCircle";
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Container from '@mui/material/Container';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import { mainListItems, secondaryListItems } from './listItems';
import Chart from './Chart';
import Deposits from './Deposits';
import Orders from './Orders';
import { useNavigate } from 'react-router-dom';
import ReactLoading from 'react-loading';
import TrialModalContainer from '../../Nav/TrialModalContainer';
// Auth
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { getDatabase, ref, child, get, update } from "firebase/database";
import { getStorage, getDownloadURL, ref as storageRef } from "firebase/storage";
import axios from 'axios';
import ProfileMenu from '../../Nav/ProfileMenu';
import AcpsCompara from './AcpsCompara';
import SwotAnalysis from './SwotAnalysis';
import Campaign from './campaign/Campaign';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ReactJoyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import LightThemeImage from '../../../assets/img/light_theme.png';
import DarkThemeImage from '../../../assets/img/dark_theme.png';
import Confetti from 'react-confetti';
import ActionAssistant from './ActionAssistant';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';

const yesterday = new Date(Date.now() - 86400000).toLocaleString({ timeZone: 'America/Sao_Paulo' }).split(',')[0];
const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: 1501,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
        '& .MuiListItemText-primary, & .MuiListItemText-secondary': {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      }),
    },
  }),
);

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#9c27b0',
    },
  },
});

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#282a36',
      paper: '#3a3d4d',
    },
    text: {
      primary: '#f8f8f2',
      secondary: '#6272a4',
    },
    primary: {
      main: '#f5bb09',
    },
    secondary: {
      main: '#3a5c38',
    },
    divider: '#6272a4',
  },
});

function formatNumberWithLeadingZero(number) {
  let numberString = number.toString();
  if (numberString.length === 1) {
    numberString = '0' + numberString;
  }
  return numberString;
}

const handleApiRes = (chartOpt, index, data, date_range_begin, date_range_end) => {
  if (chartOpt < 2) {
    const updatedDate = new Date(`${date_range_begin}T00:00:00-03:00`);
    updatedDate.setDate(index);
    const dateString = updatedDate.toISOString().split('T')[0];
    const matchingObj = data.find(item => item.date === dateString);
    return matchingObj ? parseFloat(matchingObj.acps.toFixed(3)) : null;
  } else {
    const startDate = new Date(date_range_begin);
    const endDate = new Date(date_range_end);
    const monthlyAverage = {};
    let lastKnownACPS = 0;
    for (let d = new Date(startDate); d <= endDate;) {
      const monthKey = d.toISOString().slice(0, 7);
      monthlyAverage[monthKey] = { sum: 0, count: 0 };
      d.setMonth(d.getMonth() + 1);
      d.setDate(1);
    }
    data.forEach(item => {
      const itemDate = new Date(item.date);
      if (itemDate >= startDate && itemDate <= endDate) {
        const monthKey = item.date.slice(0, 7);
        monthlyAverage[monthKey].sum += item.acps;
        monthlyAverage[monthKey].count++;
        lastKnownACPS = item.acps;
      }
    });
    Object.keys(monthlyAverage).forEach(key => {
      if (monthlyAverage[key].count === 0) {
        monthlyAverage[key].sum = lastKnownACPS;
        monthlyAverage[key].count = 1;
      }
    });
    const averages = Object.entries(monthlyAverage).map(([month, {sum, count}]) => {
      return { month, average: sum / count };
    });
    const monthNames = ["jan", "fev", "mar", "abr", "mai", "jun", "jul", "ago", "set", "out", "nov", "dez"];
    const result = averages.map(({month, average}) => {
      const [year, monthIndex] = month.split("-");
      return `${monthNames[parseInt(monthIndex, 10) - 1]}-${year}`;
    });
    return [result, averages[index - 1] ? parseFloat(averages[index - 1].average.toFixed(3)) : lastKnownACPS];
  }
};

function modelData(day, ACPS_CLIENT, ACPS_COMP1, ACPS_COMP2, ACPS_COMP3, ACPS_SEG) {
  return { day, ACPS_CLIENT, ACPS_COMP1, ACPS_COMP2, ACPS_COMP3, ACPS_SEG };
}

const createComprehensiveData = (activePeriod, acpsClientData) => {
  let now = new Date();
  let localOffset = now.getTimezoneOffset() / 60;
  let targetOffset = -3;
  let offsetDifference = localOffset - targetOffset;
  let currentDate = new Date(now.getTime() + offsetDifference * 60 * 60 * 1000);
  var lastDataAprox = acpsClientData[acpsClientData.length - 1]['date'];
  var data = {
    daily: [],
    monthly: [],
    yearly: [],
    weekly: [],
  };

  for (let chart = 0; chart <= 3; chart++) {
    let dateBegin, dateEnd;
    switch (chart) {
      case 0:
        currentDate.setMonth(currentDate.getMonth() + activePeriod);
        dateBegin = activePeriod == 0 ? lastDataAprox : `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-01`;
        dateEnd = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).toISOString().split('T')[0];
        break;
      case 1:
        dateBegin = lastDataAprox;
        dateEnd = dateBegin;
        break;
      case 2:
        const monthlyAverage = {};
        acpsClientData.forEach(item => {
          const itemDate = new Date(item.date);
          const monthKey = item.date.slice(0, 7);
          if (!monthlyAverage[monthKey]) {
            monthlyAverage[monthKey] = { sum: 0, count: 0 };
          }
          monthlyAverage[monthKey].sum += item.acps;
          monthlyAverage[monthKey].count++;
        });
        Object.keys(monthlyAverage).forEach(monthKey => {
          const monthNames = ["jan", "fev", "mar", "abr", "mai", "jun", "jul", "ago", "set", "out", "nov", "dez"];
          const [year, monthIndex] = monthKey.split("-");
          const formattedMonth = `${monthNames[parseInt(monthIndex, 10) - 1]}-${year}`;
          const average = monthlyAverage[monthKey].sum / monthlyAverage[monthKey].count;
          data.yearly.push(modelData(formattedMonth, average, null, null, null, null));
        });
        break;
      case 3:
        const weeklyAverage = {};
        let lastKnownACPS = 0;
        acpsClientData.forEach(item => {
          const itemDate = new Date(item.date);
          const [year, week] = getWeekNumber(itemDate);
          const weekKey = `${year}-W${week}`;
          if (!weeklyAverage[weekKey]) {
            weeklyAverage[weekKey] = { sum: 0, count: 0 };
          }
          weeklyAverage[weekKey].sum += item.acps;
          weeklyAverage[weekKey].count++;
          lastKnownACPS = item.acps;
        });
        Object.keys(weeklyAverage).forEach(weekKey => {
          if (weeklyAverage[weekKey].count > 0) {
            const average = weeklyAverage[weekKey].sum / weeklyAverage[weekKey].count;
            data.weekly.push({ week: weekKey, acps: parseFloat(average.toFixed(3)) });
          } else {
            data.weekly.push({ week: weekKey, acps: lastKnownACPS });
          }
        });
        break;
    }
    if (chart !== 2) {
      let whileLimit = chart === 2 ? 12 : 31;
      for (let dayStart = 1; dayStart <= whileLimit; dayStart++) {
        var cleanClientAcps = handleApiRes(chart, dayStart, acpsClientData, dateBegin, dateEnd);
        let dataPoint = modelData(
          chart < 2 ? formatNumberWithLeadingZero(dayStart) : cleanClientAcps[0],
          chart < 2 ? cleanClientAcps : cleanClientAcps[1]
        );
        switch (chart) {
          case 0:
            data.monthly.push(dataPoint);
            break;
          case 1:
            data.daily.push(dataPoint);
            break;
        }
      }
    }
  }
  return data;
};

function getWeekNumber(d) {
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
  var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
  var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
  return [d.getUTCFullYear(), weekNo];
}

const prevPeriodOfMonthChart = (activePeriodOfMonthChart, setActivePeriodOfMonthChart) => {
  return activePeriodOfMonthChart > -12 ? setActivePeriodOfMonthChart(activePeriodOfMonthChart - 1) : null;
};

const nextPeriodOfMonthChart = (activePeriodOfMonthChart, setActivePeriodOfMonthChart) => {
  return activePeriodOfMonthChart < 0 ? setActivePeriodOfMonthChart(activePeriodOfMonthChart + 1) : null;
};

const disabledItems = {
  marca: false,
  swot: false,
  compara: false,
  campanha: false,
  acao: false,
};

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" marginTop="15px">
      {`Ian versão `}
      {props.version}
      {` - Todos os direitos reservados ® `}
      <Link color="inherit" href="https://vinbol.ai/">
        Vinbol
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function Dashboard() {  
  const { company } = useParams();
  const API_URI_CLIENT_ACPS = `https://api-vinbolai-dev.azurewebsites.net/acps`;
  const API_URI_CNPJS = `https://api-vinbolai-dev.azurewebsites.net/cnpjs/?cnpj=`;
  
  const [FilterConcorrente1, setFilterConcorrente1] = React.useState(false);
  const [FilterConcorrente2, setFilterConcorrente2] = React.useState(false);
  const [FilterConcorrente3, setFilterConcorrente3] = React.useState(false);
  const [FilterSegmento, setFilterSegmento] = React.useState(false);
  const [nomeEmpresa, setNomeEmpresa] = React.useState('');
  const [displayNomeEmpresa, setDisplayNomeEmpresa] = React.useState('');
  const [acpsClientData, setAcpsClientData] = React.useState([]);
  const [acpsGoodCommentsData, setAcpsGoodCommentsData] = React.useState([]);
  const [acpsBadCommentsData, setAcpsBadCommentsData] = React.useState([]);
  const [nome, setNome] = React.useState('');
  const [cnpj, setCnpj] = React.useState([]);
  const [currCnpj, setCurrCnpj] = React.useState('');
  const [userTier, setUserTier] = React.useState(0);
  const [loadScreen, setLoadScreen] = React.useState(false);
  const [TrialCount, setTrialCount] = React.useState(0);
  const [trialExpired, setTrialExpired] = React.useState(false);
  const [userCompany, setUserCompany] = React.useState('');
  const [activePeriodOfMonthChart, setActivePeriodOfMonthChart] = React.useState(0);
  const [version, setVersion] = React.useState('3.0.0');
  const [postsLikes, setPostsLikes] = React.useState(0);
  const [isGroup, setIsGroup] = React.useState(false);
  const [onCampaign, setOnCampaign] = React.useState(false);
  const [groupCompanies, setGroupCompanies] = React.useState({});
  const [groupCompaniesCnpjsStr, setGroupCompaniesCnpjsStr] = React.useState({});
  const [comprehensiveData, setComprehensiveData] = React.useState({ daily: [], monthly: [], yearly: [], weekly: [] });
  const [filterPostUrl, setFilterPostUrl] = React.useState("");
  const [showModalFilterPublicacao, setShowModalFilterPublicacao] = React.useState(false);
  const [showCompara, setShowCompara] = React.useState(false);
  const [showCampanha, setShowCampanha] = React.useState(false);
  const [showSwot, setShowSwot] = React.useState(false);
  const [openTrialModal, setOpenTrialModal] = React.useState(false);
  const [selectedItemId, setSelectedItemId] = React.useState('marca');
  const [campaignPeriod, setCampaignPeriod] = React.useState({});
  const [activeTutorial, setActiveTutorial] = React.useState(false);
  const [showWelcomeModal, setShowWelcomeModal] = React.useState(false);
  const [showThemeModal, setShowThemeModal] = React.useState(false);
  const [showTourReadyModal, setShowTourReadyModal] = React.useState(false);
  const [showCalculator, setShowCalculator] = React.useState(false);
  const [darkMode, setDarkMode] = React.useState(false);
  const [tourSteps, setTourSteps] = React.useState([
    {
      target: '#dashboard-menu-item',
      title: 'Minha marca',
      content: 'Aqui você conhece a situação atual da sua marca.',
      disableBeacon: true,
    },
    {
      target: '#deposits-chart',
      title: 'Minha marca',
      content: 'Este gráfico mostra o valor do ACPS da sua marca no dia anterior, categorizando-o em quatro zonas diferentes.',
    },
    {
      target: '#chart-component',
      title: 'Minha marca',
      content: 'Este gráfico exibe como o ACPS da sua marca se distribui diariamente ao longo do mês. Navegue ao longo dos meses através das setas de navegação no canto superior direito.',
    },
    {
      target: '.chart-point', // Step for clickable points on the chart
      content: 'Ao passar o mouse sobre o gráfico, os pontos ficarão vísiveis. Clique em um ponto do gráfico para ver os detalhes de cálculo do ACPS da sua marca nesse dia específico.',
      spotlightClicks: true, // Enables click actions during the tour
    },
    {
      target: '#acps-details-modal',
      title: 'Detalhes do ACPS®',
      content: 'Aqui você pode ver os detalhes do ACPS® para a data selecionada, incluindo sentimentos positivos e negativos, menções e muito mais.',
      disableBeacon: true, // Keeps the step from auto-triggering
      spotlightClicks: true, // Allows clicking on the modal
    },
    {
      target: '#orders-chart', 
      title: 'Minha marca',
      content: 'Este gráfico apresenta uma visão acumulada do ACPS ao longo dos meses anteriores, facilitando a análise da evolução da percepção da marca.',
    },
    {
      target: '#swot-menu-item',
      title: 'Análise SWOT Avançada',
      content: 'Realize uma análise SWOT para identificar forças, fraquezas, oportunidades e ameaças.',
    },
    {
      target: '#compara-menu-item',
      title: 'ACPS Compara',
      content: 'Entenda se a sua percepção pública está alinhada com seus objetivos de negócio, comparando o ACPS da sua marca com indicadores de negócio.',
    },
    {
      target: '#data-table',
      title: 'ACPS Compara',
      content: 'Preencha a tabela, definindo o indicador de negócio e suas metas.',
    },
    {
      target: '#chart-compara',
      title: 'Assistente de ação',
      content: 'Visualize o cruzamento entre o ACPS e o indicador de negócio selecionado.',
    },
    {
      target: '#acao-menu-item',
      title: 'Assistente de ação',
      content: 'Em breve você terá acesso a um plano de ação completo para melhorar a percepção pública do seu negócio.',
    },
    {
      target: '#campanha-menu-item',
      title: 'Campanha',
      content: 'Avalie o impacto de suas campanhas sobre o seu negócio.',
    },
    {
      target: '#acps-daily-campaign',
      title: 'Campanha',
      content: 'Visualize o ACPS diário da sua campanha',
    },
    {
      target: '#acps-weekly-campaign',
      title: 'Campanha',
      content: 'Visualize o ACPS semanal da sua campanha',
    },
    {
      target: '#swot-analysis-campaign',
      title: 'Campanha',
      content: 'Realize uma análise SWOT no contexto da campanha',
    },
    {
      target: '#acps-compara-campaign',
      title: 'Campanha',
      content: 'Entenda se sua campanha está alinhada com seus objetivos de negócio.',
    },
    {
      target: '#perfil-menu-item',
      title: 'Perfil',
      content: 'Acesse e edite as informações do seu perfil.',
    },
  ]);
  const [confettiEnabled, setConfettiEnabled] = React.useState(true);
  const joyrideRef = React.useRef(null);
  const [dataRobustnessIndex, setDataRobustnessIndex] = React.useState({ monthly: [], yearly: [] });
  const [processedRobustnessData, setProcessedRobustnessData] = React.useState({ monthly: [], yearly: [] });
  const [actionAssistantOpen, setActionAssistantOpen] = React.useState(false);
  const [companyConfig, setCompanyConfig] = React.useState(null);
  const [logoUrl, setLogoUrl] = React.useState(null);

  const handleJoyrideCallback = (data) => {
    const { status, index, action, step } = data;
  
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setActiveTutorial(false);
    }
  
    // Check if we are on the #swot-menu-item step
    if (step.target === '#swot-menu-item' && action === 'next') {
      updateOnFilterSwot(); // Trigger the updateOnFilterSwot function
    } else if (step.target === '#compara-menu-item' && action === 'next') {
      updateOnFilterCompara(); // Trigger the updateOnFilterCompara function
    } else if (step.target === '#campanha-menu-item' && action === 'next') {
      updateOnFilterCampanha(); // Trigger the updateOnFilterCampanha function
    }
  };

  const getJoyrideStyles = (darkMode) => ({
    options: {
      arrowColor: '#d4a007',
      backgroundColor: '#d4a007', // Set background color of pop-ups
      overlayColor: 'rgba(0, 0, 0, 0.5)',
      primaryColor: darkMode ? '#f5bb09' : '#1976d2',
      textColor: '#ffffff', // Set font color to white
      zIndex: 2000, // Ensure pop-ups are above other elements
    },
    buttonNext: {
      backgroundColor: '#ffffff', // Button background
      color: '#d4a007', // Button text color
      fontWeight: 'normal', // Reduce font weight
    },
    buttonBack: {
      color: '#ffffff', // Back button color
      fontWeight: 'normal', // Reduce font weight
    },
    buttonSkip: {
      color: '#ffffff', // Set Skip button color to white
      fontWeight: 'normal', // Reduce font weight
    },
    tooltip: {
      backgroundColor: '#d4a007', // Set background color of the tooltip
      borderRadius: '4px',
      boxShadow: darkMode ? '0 0 15px rgba(0, 0, 0, 0.5)' : '0 0 15px rgba(0, 0, 0, 0.1)',
      color: '#ffffff', // Font color of the tooltip
      fontWeight: 'normal', // Reduce font weight
    },
    tooltipContainer: {
      textAlign: 'left',
      fontWeight: 'normal', // Reduce font weight for content
    },
    tooltipTitle: {
      color: '#ffffff', // Title font color
      fontWeight: 'normal', // Reduce font weight for title
    },
  });
  
  const joyrideStyles = getJoyrideStyles(darkMode);
  
  const onCloseTrialModal = () => setOpenTrialModal(false);

  const handleFilterPostUrlInputChange = (event) => {
    setFilterPostUrl(event.target.value);
  };

  const updateOnFilterConcorrente1 = React.useCallback(() => {
    setFilterConcorrente1(true);
    setFilterConcorrente2(false);
    setFilterConcorrente3(false);
    setFilterSegmento(false);
    setShowCompara(false);
    setShowSwot(false);
    setShowCampanha(false);
  });
  const updateOnFilterConcorrente2 = React.useCallback(() => {
    setFilterConcorrente1(false);
    setFilterConcorrente2(true);
    setFilterConcorrente3(false);
    setFilterSegmento(false);
    setShowCompara(false);
    setShowSwot(false);
    setShowCampanha(false);
  });
  const updateOnFilterConcorrente3 = React.useCallback(() => {
    setFilterConcorrente1(false);
    setFilterConcorrente2(false);
    setFilterConcorrente3(true);
    setFilterSegmento(false);
    setShowCompara(false);
    setShowSwot(false);
    setShowCampanha(false);
  });
  const updateOnFilterMarca = React.useCallback(() => {
    setOnCampaign(false);
    setFilterConcorrente1(false);
    setFilterConcorrente2(false);
    setFilterConcorrente3(false);
    setFilterSegmento(false);
    setShowCompara(false);
    setShowSwot(false);
    setShowCampanha(false);
  });
  const updateOnFilterSegmento = React.useCallback(() => {
    setFilterConcorrente1(false);
    setFilterConcorrente2(false);
    setFilterConcorrente3(false);
    setFilterSegmento(true);
    setShowCompara(false);
    setShowSwot(false);
    setShowCampanha(false);
  });
  const handleModalFilterPublicacao = React.useCallback(() => {
    setShowModalFilterPublicacao(true);
    setFilterConcorrente1(false);
    setFilterConcorrente2(false);
    setFilterConcorrente3(false);
    setFilterSegmento(false);
    setShowCompara(false);
    setShowSwot(false);
    setShowCampanha(false);
  });
  const checkAndCloseModalFilterPublicacao = React.useCallback(() => {
    if (!filterPostUrl || (filterPostUrl.includes('/p/') || filterPostUrl.includes('/reel/'))) {
      setShowModalFilterPublicacao(false);
    } else {
      alert('Esse não é um endereço válido, por favor, digite o endereço de uma publicação.')
    }
  });
  const updateOnFilterCompara = React.useCallback(() => {
    setOnCampaign(false);
    setShowCampanha(false);
    setShowCompara(true);
    setShowSwot(false);
    setFilterConcorrente1(false);
    setFilterConcorrente2(false);
    setFilterConcorrente3(false);
    setFilterSegmento(false);
  });
  const updateOnFilterSwot = React.useCallback(() => {
    setOnCampaign(false);
    setShowCampanha(false);
    setShowSwot(true);
    setShowCompara(false);
    setFilterConcorrente1(false);
    setFilterConcorrente2(false);
    setFilterConcorrente3(false);
    setFilterSegmento(false);
  });
  const updateOnFilterCampanha = React.useCallback(() => {
    setOnCampaign(true);
    setShowCampanha(true);
    setShowCompara(false);
    setShowSwot(false);
    setFilterConcorrente1(false);
    setFilterConcorrente2(false);
    setFilterConcorrente3(false);
    setFilterSegmento(false);
  });
  const updateOnCalculator = React.useCallback(() => {
    setOnCampaign(false);
    setShowCampanha(false);
    setShowCompara(false);
    setShowSwot(false);
    setFilterConcorrente1(false);
    setFilterConcorrente2(false);
    setFilterConcorrente3(false);
    setFilterSegmento(false);
    setShowCalculator(true);
  });

  const handleModalFilterCampanha = () => {
    setShowModalFilterPublicacao(true);
  };

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
    const auth = getAuth();
    const uid = auth.currentUser?.uid;
    if (uid) {
      update(ref(getDatabase(), `users/${uid}`), {
        isDashboardDarkMode: !darkMode,
      });
    }
  };

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;

  React.useEffect(() => {
    const dbRef = ref(getDatabase()); // Referência ao banco de dados Firebase
    const companyPath = company || 'default'; // Usar 'default' se a empresa não for encontrada
  
    // Buscar a configuração da empresa no Realtime Database
    get(child(dbRef, `white-label/companyConfigs/${companyPath}`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          const config = snapshot.val();
  
          // Definir as configurações da empresa
          setCompanyConfig(config);
  
          // Carregar o logo do Firebase Storage
          const logoStorageRef = storageRef(getStorage(), darkMode ? config.logo.onDarkTheme : config.logo.onLightTheme);
          getDownloadURL(logoStorageRef)
            .then((url) => {
              setLogoUrl(url); // Define a URL do logo
            })
            .catch((error) => {
              console.error('Error fetching logo URL:', error);
            });
        } else {
          console.log("No data available for the company");
        }
      })
      .catch((error) => {
        console.error("Error fetching company config:", error);
      });
  }, [darkMode]);
  

  React.useEffect(() => {
    const auth = getAuth(); 
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
        const dbRef = ref(getDatabase());
  
        get(child(dbRef, `users/${uid}`))
          .then((snapshot) => {
            if (snapshot.exists()) {
              if (!snapshot.val().isActive) {
                alert("Estamos terminando de processar as informações da sua marca. Sua conta será liberada em breve!");
                navigate("/");
              } else {
                const userData = snapshot.val();
                setNome(userData.firstName + " " + userData.lastName);
                setUserTier(userData.tier);
                setCnpj(userData.cnpj);
                setUserCompany(userData.company);
                if (userData.isOnboarding) {
                  setShowWelcomeModal(true);
                }
                setDarkMode(userData.isDashboardDarkMode || false);
  
                const createdOnTimestamp = userData.createdOn;
                const trialDays = userData.trialDays;
                const currentDate = new Date();
                const createdOnDate = new Date(createdOnTimestamp);
                const targetDate = new Date(createdOnDate);
                targetDate.setDate(targetDate.getDate() + trialDays);
                const timeDifference = targetDate - currentDate;
                const daysDifference = Math.floor(timeDifference / (24 * 60 * 60 * 1000));
  
                setTrialCount(daysDifference);
                if (daysDifference < 0) {
                  setTrialExpired(true); 
                }
              }
            } else {
              console.log("No data available");
            }
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        // User is not logged in
        alert("Você precisa estar logado para acessar esta página.");
        navigate("/signin");
      }
    });
  }, []);

  // Função para buscar os dados do Índice de Robustez de Dados
  React.useEffect(() => {
    if (currCnpj) {
      axios.get(`https://api-vinbolai-dev.azurewebsites.net/data_robustness_index?cnpj=${currCnpj}`)
        .then(res => {
          setDataRobustnessIndex(res.data);
        })
        .catch(error => {
          console.error("Error fetching Data Robustness Index:", error);
        });
    }
  }, [currCnpj]);

  const handleStartOnboarding = () => {
    setShowWelcomeModal(false);
    setShowThemeModal(true);
  };

  const handleThemeSelection = (isDarkMode) => {
    setDarkMode(isDarkMode);
    setShowThemeModal(false);
    setShowTourReadyModal(true); // Open the third modal after theme selection
  
    const auth = getAuth();
    const uid = auth.currentUser?.uid;
  
    if (uid) {
      update(ref(getDatabase(), `users/${uid}`), {
        isDashboardDarkMode: isDarkMode,
        isOnboarding: false, // Set onboarding to false after selection
      });
    }
  };

  // Process the data to match the comprehensiveData structure
  React.useEffect(() => {
    const processRobustnessData = () => {
      const robustnessDataDict = {
        monthly: [],
        yearly: []
      };

      // Processing monthly data similar to the way ACPS data is processed
      if (comprehensiveData.monthly && comprehensiveData.monthly.length > 0) {
        const currentMonth = comprehensiveData.monthly[activePeriodOfMonthChart];
        const monthlyRobustnessData = Array.from({ length: 31 }, (_, i) => ({
          day: (i + 1).toString().padStart(2, '0'),
          dataRobustnessIndex: null,
        }));

        dataRobustnessIndex.forEach(item => {
          const day = item.date.split('-')[2]; // Assuming format "yyyy-mm-dd"
          const found = monthlyRobustnessData.find(d => d.day === day);
          if (found) {
            found.dataRobustnessIndex = item.robustness_index;
          }
        });

        robustnessDataDict.monthly = monthlyRobustnessData;
      }

      // Processing yearly data based on the provided example
      const yearlyRobustnessData = dataRobustnessIndex.map(item => ({
        day: item.day, // List of months as provided in the example
        dataRobustnessIndex: item.yearly || 0, // Assuming yearly robustness index is directly available
      }));

      robustnessDataDict.yearly = yearlyRobustnessData;

      setProcessedRobustnessData(robustnessDataDict);
    };

    if (dataRobustnessIndex.length > 0) {
      processRobustnessData();
    }
  }, [dataRobustnessIndex, activePeriodOfMonthChart, comprehensiveData]);

  React.useEffect(() => {
    if (cnpj.length > 0) {
      let isGroup = false;
      axios
      .get(`${API_URI_CNPJS}${cnpj[0]}`)
      .then((res) => {
        isGroup = res.data[0].grupo;
        setNomeEmpresa(res.data[0].nome_pesquisa);
        if (isGroup) {
          setIsGroup(isGroup);
          setDisplayNomeEmpresa('Grupo');
          const TOKEN = "VOkVTET8-2Qvrvl8qIHFRhUFDujQ1luE6yUZBmpuP1KtAzFu-8KV-A==";
          axios
          .get(`https://query-group-companies.azurewebsites.net/api/groupCnpjs?code=${TOKEN}&cnpj=${cnpj[0]}`)
          .then( res => {
            setGroupCompanies(res.data);
            setGroupCompaniesCnpjsStr(res.data.map(company => company.cnpj).join(','));
            setCurrCnpj(res.data.map(company => company.cnpj).join(','));
          });
        } else {
          setDisplayNomeEmpresa(res.data[0].nome_pesquisa);
          setCurrCnpj(cnpj[0]);
        }
      });
    }
  }, [cnpj]);

  React.useEffect(() => {
    if (!filterPostUrl && currCnpj) {
      console.log(`${API_URI_CLIENT_ACPS}?cnpj=${currCnpj}&version=${version}`)
      axios
      .get(`${API_URI_CLIENT_ACPS}?cnpj=${currCnpj}&version=${version}`)
      .then(res => {
        setAcpsClientData(res.data);
      });
    }
  },[version,currCnpj,filterPostUrl]);

  React.useEffect(() => {
    if (currCnpj) {
      const fetchCampaignPeriod = (cnpj) => {
        const dbRef = ref(getDatabase());
        let cnpjClient = cnpj?.replace(/[./-]/g, '');
        get(child(dbRef, `campaign-analysis/${cnpjClient}`))
          .then((snapshot) => {
            if (snapshot.exists()) {
              const keys = Object.keys(snapshot.val());
              keys.sort((a, b) => b - a);
              const lastCampaignKey = keys[0];
              setCampaignPeriod(snapshot.val()[lastCampaignKey].period);
            } else {
              console.log("No data available");
            }
          })
          .catch((error) => {
            console.error(error);
          });
      };
      fetchCampaignPeriod(isGroup ? currCnpj.split(',')[0] : currCnpj);
    }
  }, [currCnpj, isGroup]);

  React.useEffect(() => {
    if (filterPostUrl) {
      axios
      .get(`${API_URI_CLIENT_ACPS}?cnpj=${currCnpj}&post_url=${filterPostUrl}&version=${version}`)
      .then(res => {
        setAcpsClientData(res.data);
      });

    }
  }, [filterPostUrl]);

  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = React.useState(true);
  const toggleDrawer = () => {
    if (actionAssistantOpen) {
      setActionAssistantOpen(false);
    }
    setDrawerOpen(!drawerOpen);
  };

  const toggleActionAssistant = () => {
    setActionAssistantOpen(!actionAssistantOpen);
    setDrawerOpen(actionAssistantOpen); // Toggle drawer based on action assistant state
  };

  const auth = getAuth();
  const signOutUser = () => {
    signOut(auth)
    .then(() => {
      navigate("/signin");
    });
  };

  function findLastDefinedIndex(array){
    let lastIndex = -1;
    for (let i = array.length - 1; i >= 0; i--) {
      if (array[i].ACPS_CLIENT !== null) {
        lastIndex = i;
        return lastIndex;
      }
    }
  }

  React.useEffect(() => {
    if (Object.keys(comprehensiveData.daily).length > 0 &&
    Object.keys(comprehensiveData.monthly).length > 0 &&
    Object.keys(comprehensiveData.yearly).length > 0 &&
    companyConfig) { setLoadScreen(true); }
  }, [comprehensiveData]);

  React.useEffect(() => {
    if (acpsClientData.length>0) {
        const data = createComprehensiveData(activePeriodOfMonthChart, acpsClientData);
        setComprehensiveData(data);
    } 
  }, [activePeriodOfMonthChart, acpsClientData]);

  const lastDefinedIndex = findLastDefinedIndex(comprehensiveData.daily);

  function LoadingScreen({ darkMode }) {
    return (
      <div
        style={{
          flexDirection: 'column',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          backgroundColor: darkMode ? '#121212' : '#ffffff',
        }}
      >
        <ReactLoading
          type={'spokes'}
          color={darkMode ? '#f5bb09' : '#e7b010'}
          height={'10%'}
          width={'10%'}
        />
        <Typography
          component="h2"
          variant="h5"
          style={{
            marginTop: '100px',
            color: darkMode ? '#f5bb09' : '#375c37',
          }}
        >
          Carregando...
        </Typography>
      </div>
    );
  }

  function DefaultDisplay() {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} md={3.5} lg={3.5} id="deposits-chart">
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              height: 240,
            }}
          >
            <Deposits
              chartData={comprehensiveData.daily[lastDefinedIndex]?.ACPS_CLIENT || []}
              date={yesterday.split("-").reverse().join("/")}
              darkMode={darkMode}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={7.5} lg={8.5} id="chart-component">
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              height: 240,
            }}
          >
            <Chart 
              userTier={userTier}
              chartData={comprehensiveData.monthly}
              clientCnpj={currCnpj}
              clientGoodCommentsData={acpsGoodCommentsData}
              clientBadCommentsData={acpsBadCommentsData}
              activePeriodOfMonthChart={activePeriodOfMonthChart}
              onPrevPeriodOfMonthChart={() => prevPeriodOfMonthChart(activePeriodOfMonthChart, setActivePeriodOfMonthChart)}
              onNextPeriodOfMonthChart={() => nextPeriodOfMonthChart(activePeriodOfMonthChart, setActivePeriodOfMonthChart)}
              nomeEmpresa={isGroup ? nomeEmpresa : displayNomeEmpresa}
              postsLikes={postsLikes}
              filterPostUrl={filterPostUrl}
              cnpjClient={cnpj}
              trialExpired={trialExpired}
              darkMode={darkMode}
              isTourActive={activeTutorial}
              joyrideRef={joyrideRef}
              dataRobustnessIndex={processedRobustnessData.monthly}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} id="orders-chart">
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              height: 240,
            }}
          >
            <Orders 
              chartData={comprehensiveData.yearly}
              campaignPeriod={campaignPeriod}
              updateOnFilterCampanha={updateOnFilterCampanha}
              darkMode={darkMode}
              dataRobustnessIndex={processedRobustnessData.yearly}
            />
          </Paper>
        </Grid>
      </Grid>
    )};

    const inputRef = React.useRef(null);

    React.useEffect(() => {
        if (showModalFilterPublicacao) {
            inputRef.current?.focus();
        }
    }, [showModalFilterPublicacao]);

  return (
    <>
    {!loadScreen ? (
      <LoadingScreen darkMode={darkMode} />
    ) : (
      <>
      {activeTutorial && (
        <ReactJoyride
          ref={joyrideRef}
          steps={tourSteps}
          continuous
          scrollToFirstStep
          showProgress
          showSkipButton
          callback={handleJoyrideCallback}
          styles={joyrideStyles}
          locale={{
            back: 'Voltar',
            close: 'Fechar',
            last: 'Fim',
            next: 'Próximo',
            skip: 'Pular',
          }}
        />
      )}

      {(showWelcomeModal && confettiEnabled) && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 300, // Ensure confetti is above the side menu
            pointerEvents: 'none', // Allow clicking through the confetti
          }}
        >
          <Confetti
            width={window.innerWidth}
            height={window.innerHeight}
            numberOfPieces={300}
          />
        </div>
      )}

      {/* Welcome Modal */}
      <Modal open={showWelcomeModal}>
        <Paper
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            height: 600,
            padding: 30,
            textAlign: 'center',
            borderRadius: 8,
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center', // Center the content horizontally
            position: 'relative', // Added for positioning the button
          }}
        >
          <Button
            style={{
              position: 'absolute',
              top: 10,
              right: 10,
              backgroundColor: 'transparent',
              color: '#757575',
              fontSize: '0.8rem',
              padding: '5px',
              minWidth: 'auto',
              textTransform: 'none',
            }}
            onClick={() => setConfettiEnabled(false)}
          >
            Desativar animação
          </Button>
          <Typography variant="h4" style={{ color: '#3a5c38', fontWeight: 'bold', marginBottom: 20 }}>
            Seja bem-vindo(a) a Vinbol!
          </Typography>
          <Typography variant="body1" style={{ marginBottom: 20, color: '#375c37', fontSize: '1.2rem' }}>
            Aqui, utilizamos o poder da Inteligência Artificial para transformar percepções em resultados. Explore nossa plataforma para descobrir como sua marca impacta o seu negócio. Juntos, vamos construir algo ainda maior, impulsionando o sucesso da sua marca de forma extraordinária.
          </Typography>
          <Button
            variant="contained"
            style={{ backgroundColor: '#f5bb09', color: '#fff', fontWeight: 'bold', fontSize: '1.1rem', padding: '10px 20px' }}
            onClick={handleStartOnboarding}
          >
            Começar
          </Button>
        </Paper>
      </Modal>

      {/* Theme Selection Modal */}
      <Modal open={showThemeModal}>
        <Paper
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            height: 600,
            padding: 30,
            textAlign: 'center',
            borderRadius: 8,
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            position: 'relative', // Added for positioning the button
          }}
        >
          <Button
            style={{
              position: 'absolute',
              top: 10,
              right: 10,
              backgroundColor: 'transparent',
              color: '#757575',
              fontSize: '0.8rem',
              padding: '5px',
              minWidth: 'auto',
              textTransform: 'none',
            }}
            onClick={() => setConfettiEnabled(false)}
          >
            Desativar animação
          </Button>
          <Typography variant="h5" style={{ color: '#3a5c38', fontWeight: 'bold', marginBottom: 20 }}>
            Escolha um tema
          </Typography>
          <Typography variant="body1" style={{ marginBottom: 30, color: '#375c37', fontSize: '1.2rem' }}>
            Selecione o modo claro ou escuro para continuar.
          </Typography>
          <Grid container spacing={4} justifyContent="center" alignItems="center">
            <Grid item xs={6} style={{ textAlign: 'center' }}>
              <Button
                onClick={() => handleThemeSelection(false)}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  border: '2px solid transparent',
                  transition: 'border-color 0.3s ease',
                }}
                onMouseEnter={(e) => (e.currentTarget.style.borderColor = '#f5bb09')}
                onMouseLeave={(e) => (e.currentTarget.style.borderColor = 'transparent')}
              >
                <img
                  src={LightThemeImage}
                  alt="Light Theme"
                  width={200}
                  height={350}
                  style={{ borderRadius: 8 }}
                />
                <Typography style={{ marginTop: 20, fontWeight: 'bold', color: '#375c37', fontSize: '1.1rem' }}>
                  Modo Claro
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={6} style={{ textAlign: 'center' }}>
              <Button
                onClick={() => handleThemeSelection(true)}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  border: '2px solid transparent',
                  transition: 'border-color 0.3s ease',
                }}
                onMouseEnter={(e) => (e.currentTarget.style.borderColor = '#3a5c38')}
                onMouseLeave={(e) => (e.currentTarget.style.borderColor = 'transparent')}
              >
                <img
                  src={DarkThemeImage}
                  alt="Dark Theme"
                  width={200}
                  height={350}
                  style={{ borderRadius: 8 }}
                />
                <Typography style={{ marginTop: 20, fontWeight: 'bold', color: '#375c37', fontSize: '1.1rem' }}>
                  Modo Escuro
                </Typography>
              </Button>
            </Grid>
          </Grid>
          <Typography variant="caption" style={{ marginTop: 20, color: '#375c37', fontSize: '0.9rem' }}>
            Você pode mudar o tema a qualquer momento no menu de perfil.
          </Typography>
        </Paper>
      </Modal>

      {/* Tour Ready Modal */}
      <Modal open={showTourReadyModal}>
        <Paper
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            height: 600,
            padding: 30,
            textAlign: 'center',
            borderRadius: 8,
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative', // Added for positioning the button
          }}
        >
          <Button
            style={{
              position: 'absolute',
              top: 10,
              right: 10,
              backgroundColor: 'transparent',
              color: '#757575',
              fontSize: '0.8rem',
              padding: '5px',
              minWidth: 'auto',
              textTransform: 'none',
            }}
            onClick={() => setConfettiEnabled(false)}
          >
            Desativar animação
          </Button>
          <Typography variant="h5" style={{ color: '#3a5c38', fontWeight: 'bold', marginBottom: 20 }}>
            Preparado(a) para conhecer a Vinbol?
          </Typography>
          <Typography variant="body1" style={{ marginBottom: 20, color: '#375c37', fontSize: '1.2rem' }}>
            O próximo passo é um tour guiado pela plataforma, onde você aprenderá a aproveitar ao máximo o que a Vinbol pode oferecer para sua marca.
          </Typography>
          <Typography variant="body2" style={{ marginBottom: 30, color: '#375c37', fontSize: '1rem' }}>
            Você pode voltar ao tour guiado a qualquer momento pelo menu de perfil no canto superior direito.
          </Typography>
          <Button
            variant="contained"
            style={{ backgroundColor: '#f5bb09', color: '#fff', fontWeight: 'bold', fontSize: '1.1rem', padding: '10px 20px' }}
            onClick={() => {
              setShowTourReadyModal(false);
              setActiveTutorial(true); // Start the guided tour after closing the modal
            }}
          >
            Começar Tour
          </Button>
        </Paper>
      </Modal>

      {/* Main Dashboard content */}
      <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <AppBar
            // style={!onCampaign ? { background: darkMode ? '#d4a007' : '#e2aa13' } : { background: darkMode ? '#3a5c38' : '#6c9434' }}
            style={!onCampaign ? { background: companyConfig?.primaryColor } : { background: companyConfig?.secondaryColor } }
            position="absolute"
            open={drawerOpen}
          >
            <Toolbar
              sx={{
                pr: '24px',
              }}
            >
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer}
                sx={{
                  marginRight: '36px',
                  ...(drawerOpen && { display: 'none' }),
                }}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                sx={{ flexGrow: 0, flexShrink: 0 }}
              >
                Dashboard {!onCampaign ? "de Marca" : "de Campanha"}
              </Typography>
              <Paper
                style={{
                  backgroundColor: 'rgba(169, 169, 169, 0.2)',
                  padding: '8px',
                  height: "40px",
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexGrow: 1,
                  marginLeft: '20px',
                  marginRight: '20px',
                  flexShrink: 1
                }}
              >
                <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                  <Typography
                    align="center"
                    justifyContent="center"
                    noWrap
                    component="p"
                    variant="h7"
                    style={{
                      fontFamily: "Roboto Mono, monospace",
                      fontSize: "14px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: '100%'
                    }}
                  >
                    {
                      userTier === 0 && TrialCount < 0 ?
                      <span style={{
                        backgroundColor: "#cf493a",
                        color: "white",
                        padding: "5px 10px",
                        borderRadius: "5px",
                        fontWeight: "bold",
                        cursor: "pointer",
                        flexGrow: 1
                      }}
                      >
                        O PERIODO DE AVALIAÇÃO TERMINOU
                      </span> :
                      userTier === 1 ? 'Plano Marca' :
                      userTier === 2 ? 'Plano Campanha' :
                      userTier === 3 ? 'Plano Marca+Campanha' :
                      userTier === -1 ? `Plano Marca+Campanha (Gerido por ${userCompany})` :
                      null
                    }
                    {
                      ((userTier === 0 && TrialCount < 0) || userTier === 1 || userTier === 2 || userTier === 3 || userTier === -1) ? null :
                      <>
                          <span style={{
                          backgroundColor: "#4CAF50",
                          color: "white",
                          padding: "5px 10px",
                          borderRadius: "5px",
                          fontWeight: "bold",
                          cursor: "pointer",
                          flexGrow: 1
                        }}
                        >
                        {TrialCount >= 1 || TrialCount < -1 ? "RESTAM " : "RESTA "} <u>{TrialCount} {TrialCount > 1 || TrialCount < -1 ? "DIAS" : "DIA"}</u> DO PERIODO DE AVALIAÇÃO
                        </span>
                      </>
                    }
                  </Typography>
                  <TrialModalContainer
                  open={openTrialModal}
                  onClose={onCloseTrialModal}
                  trialExpired={trialExpired}
                  userTier={userTier}
                  />
                </Box>
              </Paper>
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                sx={{ flexGrow: 0, flexShrink: 1 }}
              >
                {nome} | {isGroup ? nomeEmpresa.toUpperCase() : displayNomeEmpresa.toUpperCase()}
              </Typography>
              <ProfileMenu
                clientName={nomeEmpresa}
                setNomeEmpresa={setNomeEmpresa}
                groupNamesList={groupCompanies}
                isGroup={isGroup}
                onLogout={() => signOutUser()}
                currCnpj={currCnpj}
                setCurrCnpj={setCurrCnpj}
                cnpj={cnpj}
                displayNomeEmpresa={displayNomeEmpresa}
                setDisplayNomeEmpresa={setDisplayNomeEmpresa}
                setIsGroup={setIsGroup}
                activeTutorial={activeTutorial}
                setActiveTutorial={setActiveTutorial}
                updateOnCalculator={updateOnCalculator}
              />
              <IconButton color="inherit" onClick={toggleDarkMode}>
                {darkMode ? <LightModeIcon /> : <DarkModeIcon />}
              </IconButton>
            </Toolbar>
          </AppBar>

          <Drawer variant="permanent" open={drawerOpen}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginLeft: !company ? '30px' : '15px',
              px: [1],
            }}
          >
            {company ? (
              // If white-label, use the company logo
              <img src={logoUrl} alt="Company Logo" width={160} height={60} />
            ) : (
              <>
                {/* Default: Use LogoCircle with Tooltip */}
                <LogoCircle />
                <Tooltip title="Esta versão oferece as funcionalidades essenciais para proporcionar valor imediato aos usuários, enquanto continuamos a aprimorar e expandir a plataforma com base no seu feedback.">
                  <span
                    style={{
                      marginLeft: '10px',
                      marginRight: '5px',
                      padding: '2px 6px',
                      backgroundColor: '#f5bb09',
                      color: '#3a5c38',
                      borderRadius: '4px',
                      fontSize: '10px',
                      textTransform: 'uppercase',
                    }}
                  >
                    Versão Beta
                  </span>
                </Tooltip>
              </>
            )}
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
            <Divider />
            <List component="nav">
              {mainListItems({ 
                companyConfig,
                onUpdateFilterConcorrente1: updateOnFilterConcorrente1,
                onUpdateFilterConcorrente2: updateOnFilterConcorrente2, 
                onUpdateFilterConcorrente3: updateOnFilterConcorrente3,
                onUpdateOnFilterMarca: updateOnFilterMarca,
                onUpdateOnFilterSegmento: updateOnFilterSegmento,
                onUpdateOnFilterPublicacao: handleModalFilterPublicacao,
                disabledItems,
                selectedItemId,
                setSelectedItemId,
              })}
              {secondaryListItems({
                companyConfig,
                onUpdateOnFilterCampanha: updateOnFilterCampanha,
                onUpdateOnFilterCompara: updateOnFilterCompara,
                onUpdateOnFilterSwot: updateOnFilterSwot,
                disabledItems,
                selectedItemId,
                setSelectedItemId,
                toggleActionAssistant
              })}
            </List>
          </Drawer>
          {showModalFilterPublicacao && (
            <>
              <div style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                zIndex: 100 
              }} onClick={checkAndCloseModalFilterPublicacao}></div>
              <div style={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: 'white',
                padding: '20px',
                borderRadius: '5px',
                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.26)',
                zIndex: 101, 
                width: '90%',
                maxWidth: '500px',
                overflow: 'hidden',
              }}>
                <h2>Digite o endereço da publicação</h2>
                <input ref={inputRef} type="text" value={filterPostUrl} onChange={handleFilterPostUrlInputChange} placeholder="Enter URL" style={{ width: '100%', padding: '10px', margin: '10px 0', boxSizing: 'border-box' }} />
                <button onClick={checkAndCloseModalFilterPublicacao} style={{ padding: '10px 20px' }}>Filtrar</button>
              </div>
            </>
          )}

          <ActionAssistant 
          open={actionAssistantOpen} 
          toggleDrawer={toggleActionAssistant}
          cnpj={currCnpj} 
          style={{ zIndex: 500 }} />

          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: '100vh',
              overflow: 'auto',
              position: 'relative', // Ensure relative positioning
              zIndex: 1, // Ensure this is lower than ActionAssistant
            }}
          >
            <Toolbar />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
              <div style={{ marginTop: '-10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {!showCompara && !showSwot && !showCampanha && isGroup ? <Typography component="h2" variant="h5" color={ darkMode ? "white" : "#375c37"} gutterBottom>Você está vendo dados de <Typography component="h2" variant="h5" color={darkMode ? "#f5bb09" : "#e2aa13"} display="inline" gutterBottom>{
                  displayNomeEmpresa
                }</Typography></Typography> : null}
              </div>  
              {
                !showCompara && !showSwot && !showCampanha ?
                (
                  <DefaultDisplay />
                ) : showSwot && !showCompara && !showCampanha ? (
                  <SwotAnalysis cnpjClient={currCnpj} activeTutorial={activeTutorial}/>
                ) : !showSwot && showCompara && !showCampanha ? (
                  <AcpsCompara data={comprehensiveData.weekly} cnpjClient={currCnpj} isGroup={isGroup} activeTutorial={activeTutorial} darkMode={darkMode} />
                ) : (
                  <Campaign 
                    comprehensiveData={comprehensiveData}
                    acpsClientData={acpsClientData}
                    clientGoodCommentsData={acpsGoodCommentsData}
                    clientBadCommentsData={acpsBadCommentsData}
                    currCnpj={currCnpj}
                    userTier={userTier}
                    handleModalFilterCampanha={handleModalFilterCampanha}
                    setFilterPostUrl={setFilterPostUrl}
                    isGroup={isGroup}
                    onCampaign
                    activeTutorial={activeTutorial}
                    darkMode={darkMode}
                  />
                )
              }
              <Copyright version={version} sx={{ pt: 4}} />
            </Container>
          </Box>
        </Box>
      </ThemeProvider>
      </>
    )}
    </>
  );
}
